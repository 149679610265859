
import { defineComponent, reactive } from "vue";
import { DetectSpeed } from "@/service/detect-speed";

export default defineComponent({
    setup() {
        const DS = new DetectSpeed('/speedtest.jpg', 15892780)
        let data = reactive({
            DS: DS
        })
        data.DS.runTest()
        return { data }
    }
})
