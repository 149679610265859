<template>
    <div class="launch-navbar d-flex align-items-end justify-content-center">
        <div class="btn-toolbar" role="toolbar">
            <div class="btn-group" role="group">
                <router-link v-if="isLoggedIn" to="/requirements">
                    <button
                        id="launchrequirements"
                        type="button"
                        class="btn p-2 bg-secondary border border-1 border-primary launch-menu-button"
                        name="requirements"
                    >{{ $t("buttons.requirements") }}</button>
                </router-link>
            </div>&nbsp;
            <div class="btn-group" role="group">
                <router-link v-if="isLoggedIn" to="/tutorial">
                    <button
                        id="launchtutorial"
                        type="button"
                        class="btn p-2 btn-secondary border border-1 border-primary launch-menu-button"
                        name="tutorial"
                    >{{ $t("buttons.tutorial") }}</button>
                </router-link>
            </div>&nbsp;
            <div class="btn-group" role="group">
                <router-link v-if="isLoggedIn" to="/waitingroom">
                    <button
                        id="launchwaiting"
                        type="button"
                        class="btn p-2 btn-secondary border border-1 border-primary launch-menu-button"
                        name="waiting"
                    >{{ $t("buttons.waitingRoom") }}</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
    if (sessionStorage.getItem('isLoggedIn')) {
      this.isLoggedIn = true
    }
  }
};
</script>

<style>
</style>