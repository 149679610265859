<template>
    <p id="ready-to-play-text">
        {{ $t("waitingRoom.teamName") }}
        <input
            id="TeamName"
            type="text"
            class="form-control rounded-pill"
            placeholder="Team Name"
            aria-describedby="TeamNameHelper"
            v-model="data.teamName"
            @input="changeTeamName"
        />
        {{ $t("waitingRoom.readyPlayers") }}
    </p>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { socket, publish } from "../../service/socket-io"

export default defineComponent({
    setup() {
        const data = reactive({
            teamName: sessionStorage.getItem('teamName')
        })

        const changeTeamName = () => {
            publish("team-name", data.teamName)
            sessionStorage.setItem('teamName', data.teamName)
        }
        socket.on("team-name", (actor, message) => {
            data.teamName = message
            sessionStorage.setItem('teamName', message)
        });
        return { data, changeTeamName }
    }

})
</script>

<style>
</style>