import { createApp } from "vue";
/* import store from './store' */
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./pages/HomePage.vue";
import RequirementsPage from "./pages/RequirementsPage.vue";
import TutorialPage from "./pages/TutorialPage.vue";
import WaitingRoomPage from "./pages/WaitingRoomPage.vue";
import BasePrimaryButton from "./components/base/BasePrimaryButton.vue";
import BaseSecondaryButton from "./components/base/BaseSecondaryButton.vue";
import FaqPage from "./pages/FaqPage.vue";
import { createWebHistory, createRouter } from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import CountryFlag from "vue-country-flag-next";
import BaseLanguageSelector from "./components/base/BaseLanguageSelector.vue";
import { settings } from "./settings/settings";

let theme = window.location.href.split(".")[0].split("/")[2];

// Depricated: for backwards compatibility with opa's fortuin
// Please remove in the future when opas fortuin address is changed to comply with our addresses
if (theme != "crazy" && theme != "sdg") theme = "fortune";

// Add custom favicon
let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
if (!link) {
  link = document.createElement("link");
  link.rel = "icon";
  document.getElementsByTagName("head")[0].appendChild(link);
}
link.href = theme + ".png";

// NB: fallbackLng is used for language imports. Make sure all languages are present
const i18nInitialized = i18next.init({
  debug: true,
  fallbackLng: settings.languages[theme],
  resources: {
    "en-GB": { translation: require("./locales/" + theme + "/en-GB.json") },
  },
});

i18next.languages.forEach((lang) => {
  if (lang.indexOf("-") !== -1)
    i18next.addResourceBundle(
      lang,
      "translation",
      require("./locales/" + theme + "/" + lang + ".json"),
      true,
      true
    );
});

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/requirements",
    name: "Requirments",
    component: RequirementsPage,
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: TutorialPage,
  },
  {
    path: "/waitingroom",
    name: "WaitingRoom",
    component: WaitingRoomPage,
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: FaqPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

i18nInitialized.then(() =>
  createApp(App)
    .use(router /* store */)
    .component("base-primary-button", BasePrimaryButton)
    .component("base-secondary-button", BaseSecondaryButton)
    .component("language-selector", BaseLanguageSelector)
    .component("country-flag", CountryFlag)
    .use(VueAxios, axios)
    .use(I18NextVue, { i18next })
    .mount("#app")
);
