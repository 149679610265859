<template>
  <div class="d-flex justify-content-center">
    <div
      class="flex-column col-8 gray-container p-5 shadow-sm bg-dark bg-opacity-75 rounded-3 border border-2 border-primary shadow-sm">

      <table class="table text-light">
        <thead>
          <tr>
            <td></td>
            <td>
              <h4>{{ $t("requirements.checkRequirementsBeforeContinue") }}</h4>
            </td>
          </tr>
        </thead>
        <tbody class="table-design">
          <tr>
            <td>{{ $t("requirements.videoChat.title") }}</td>
            <td>{{ $t("requirements.videoChat.content") }}</td>
          </tr>
          <tr>
            <td>{{ $t("requirements.headphones.title") }}</td>
            <td>{{ $t("requirements.headphones.content") }}</td>
          </tr>
          <tr>
            <td>{{ $t("requirements.downloadSpeed.title") }}</td>
            <td>{{ $t("requirements.downloadSpeed.content") }} <base-detect-speed /></td>
          </tr>
          <tr>
            <td>{{ $t("Multiplayer") }}</td>
            <td><base-detect-connection /></td>
          </tr>
        </tbody>
      </table>
      <slot />
    </div>
    <div>
      <BaseJitsi></BaseJitsi>
    </div>
  </div>
</template>

<script>
import BaseDetectedSpeed from "../base/BaseDetectSpeed.vue"
import BaseDetectConnection from "../base/BaseDetectConnection.vue"
import BaseJitsi from "../base/BaseJitsi.vue"
import { defineComponent } from "vue"
export default defineComponent({
  components: {
    "base-detect-speed": BaseDetectedSpeed,
    "base-detect-connection": BaseDetectConnection,
    BaseJitsi
  }
})
</script>

<style>
</style>