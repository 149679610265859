<template>
    <button class="btn btn-primary text-light" type="button">
        <slot />
    </button>
</template>

<script>
export default {};
</script>

<style>
</style>