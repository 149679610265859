import { io } from "socket.io-client";
import { settings } from "../settings/settings";

export const socket = io(settings.URL.socket);

socket.on("connect", () => {
  console.log("connect: " + socket.id);
  if (sessionStorage.getItem("gameUid") && sessionStorage.getItem("nickName"))
    joinRoom(
      sessionStorage.getItem("gameUid")!,
      sessionStorage.getItem("nickName")!
    );
});

socket.on("disconnect", () => {
  console.log("connect: " + socket.id);
});

export function joinRoom(gameUid: string, nickName: string) {
  console.log("join-room: " + gameUid);
  socket.emit("join-room", gameUid, nickName);
}

export function publish(subject: string, message: string) {
  socket.emit(
    "portal",
    sessionStorage.getItem("gameUid"),
    sessionStorage.getItem("nickName"),
    subject,
    message
  );
}
