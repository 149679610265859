<template>
    <div class="btn-group">
        <a class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <country-flag :country="data.country" size='normal' />
        </a>
        <ul class="dropdown-menu">
            <template v-for="(lang, i) in i18next.languages" :key="i" :value="lang">
                <li><a class="dropdown-item" v-on:click="changeLanguage(lang)">
                        <country-flag :country="lang.substr(3, 2)" size='normal' />
                    </a></li>
            </template>
        </ul>
    </div>
</template>

<script>
import i18next from 'i18next';
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  setup() {
    let data = reactive({
      country: i18next.resolvedLanguage.substr(3, 2)
    })
    function changeLanguage(value) {
      i18next.changeLanguage(value)
      data.country = i18next.resolvedLanguage.substr(3, 2)
    }
    return { i18next, changeLanguage, data }
  }
})
</script>