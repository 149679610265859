<template>
  <button id="model-btn" class="btn btn-primary p-5 rounded-circle text-light" type="button" name="button"
    v-on:click="launchGame(theme, i18next.resolvedLanguage)">
    {{ $t("buttons.launch") }}
  </button>
</template>

<script>
import { defineComponent } from "vue";
import PlayerLogin from "@/service/api-client";
import { settings } from "../../settings/settings";
import { socket, publish } from "../../service/socket-io";
import i18next from "i18next";
export default defineComponent({
  created() {
    socket.on("launch-game", () => {
      location.href =
        "https://" +
        settings.URL.game[this.theme] +
        "?gamecode=" +
        sessionStorage.getItem("gameCode") +
        "&lang=" +
        i18next.resolvedLanguage +
        "&players=1";
    });
  },
  setup() {
    let theme = window.location.href.split(".")[0].split("/")[2];
    // Depricated: for backwards compatibility with opa's fortuin
    // Please remove in the future when opas fortuin address is changed to comply with our addresses
    if (theme != "crazy" && theme != "sdg") theme = "fortune";
    const PL = new PlayerLogin();
    PL.endPoint = "player_logins";
    const launchGame = async (theme, lang) => {
      await PL.postRequest({
        code: sessionStorage.getItem("gameCode"),
        teamName: sessionStorage.getItem("teamName"),
      });
      publish("launch-game", true);
      location.href =
        "https://" +
        settings.URL.game[theme] +
        "?gamecode=" +
        sessionStorage.getItem("gameCode") +
        "&lang=" +
        lang +
        "&players=1";
    };

    return { launchGame, i18next, theme };
  },
});
</script>

<style></style>
