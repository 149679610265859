<template>
  <div class="row w-100 h-75 d-flex align-items-center">
    <div class="col-lg-7 col-md-12">
      <base-hero />
    </div>
    <div class="col-lg-5 col-md-12">
      <base-login-form />
    </div>
  </div>
</template>

<script>
import BaseLoginForm from '../components/base/BaseLoginForm.vue'
import BaseHero from '../components/base/BaseHero.vue'
export default {
  components: {
    'base-login-form': BaseLoginForm,
    'base-hero': BaseHero
  },
};
</script>

<style>
</style>