import { settings } from "../settings/settings";
import axios from "axios";

export abstract class PostRequest {
  response!: any
  endPoint!: string

  async postRequest(data: object) {
    return new Promise<object>((resolve) => {
      axios
        .post(
          settings.URL.api + this.endPoint,
          data,
        )
        .then(
          (response: any) => {
            resolve(response)
          },
        );
    })
  }
}


export default class PlayerLogin extends PostRequest {
  nickName!: string
  gameCode!: string

  async sendRequest() {
    return new Promise<void>((resolve) => {
      this.postRequest({ code: this.gameCode, nickName: this.nickName })
        .then((response) => {
          this.response = response
          resolve()
        })
    })
  }
}








