export class DetectSpeed {
    downloadTimeMilliSeconds!: number
    downloadSizeBytes!: number
    imageSrc!: string
    isRunning = false

    constructor(imageSrc: string, downloadSizeBytes: number) {
        this.imageSrc = imageSrc
        this.downloadSizeBytes = downloadSizeBytes
    }

    async runTest() {
        return new Promise<boolean>((resolve) => {
            this.isRunning = true
            const startTime = new Date().getTime();
            const downloadImg = new Image();
            downloadImg.src = this.imageSrc + '?v=' + (new Date()).getTime()
            downloadImg.onload = () => {
                this.downloadTimeMilliSeconds = new Date().getTime() - startTime
                this.isRunning = false
                resolve(true)
            };
        })
    }

    get downloadTimeSeconds(): number {
        return this.downloadTimeMilliSeconds / 1000
    }

    get downloadSizeBits(): number {
        return this.downloadSizeBytes * 8
    }

    get downloadSizeKiloBits(): number {
        return Math.ceil(this.downloadSizeBits / 1024)
    }

    get downloadSizeMegaBits(): number {
        return Math.ceil(this.downloadSizeKiloBits / 1024)
    }

    get speedKbps(): number {
        return this.downloadSizeKiloBits / this.downloadTimeSeconds
    }

    get speedMbps(): number {
        return this.downloadSizeMegaBits / this.downloadTimeSeconds
    }

    get resolvedSpeed(): number {
        const speed = (this.speedKbps < 1000) ? Math.round(this.speedKbps) : Math.round(this.speedMbps)
        return speed
    }

    get resolvedUnit(): string {
        const unit = (this.speedKbps < 1000) ? "kb/s" : "Mb/s"
        return unit
    }
}
