import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-border",
  role: "status"
}
const _hoisted_3 = {
  key: 0,
  class: "text-success internet-speed"
}
const _hoisted_4 = {
  key: 1,
  class: "text-danger internet-speed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.data.DS.isRunning)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.data.DS.speedMbps >= 10)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.data.DS.resolvedSpeed) + " " + _toDisplayString(_ctx.data.DS.resolvedUnit), 1))
      : _createCommentVNode("", true),
    (_ctx.data.DS.speedMbps < 10)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.data.DS.resolvedSpeed) + " " + _toDisplayString(_ctx.data.DS.resolvedUnit) + " " + _toDisplayString(_ctx.$t('requirements.lowDownloadSpeed')), 1))
      : _createCommentVNode("", true)
  ], 64))
}