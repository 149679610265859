
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        id: String,
        colorScheme: String,
        title: String,
        bodyText: String
    }
})
