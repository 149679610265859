<template>
  <div class="container-fluid h-75">
    <div class="row h-25">
      <the-launch-navbar />
    </div>
    <div class="row h-25 align-items-center">
      <requirements-list>
        <router-link v-if="isLoggedIn" to="/tutorial">
          <base-primary-button class="float-end">{{ $t("buttons.next") }}</base-primary-button>
        </router-link>
      </requirements-list>
    </div>
  </div>
</template>

<script>
import RequirementsList from "../components/requirements/RequirementsList.vue"
import TheLaunchNavBar from "../components/layout/TheLaunchNavBar.vue"
export default {
  components: {
    "requirements-list": RequirementsList,
    "the-launch-navbar": TheLaunchNavBar
  },
  created() {
    if (sessionStorage.getItem('isLoggedIn')) {
      this.isLoggedIn = true
    }
  }
};
</script>

<style>
</style>