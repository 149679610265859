<template>
  <div class="container my-4 faq-container">
    <div class="accordion accordion-flush" id="faqAccordion">
      <faq-list-item v-for="( item, index ) in $t('faq.faqList', { returnObjects: true })" v-bind:key="index" :index="index"
        :question="item.question" :answer="item.answer" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import FaqListItem from './FaqListItem.vue'
export default defineComponent({
  components: {
    'faq-list-item': FaqListItem
  }
})
</script>

<style>
</style>