<template>
   <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#faqAccordionItem' + index " aria-expanded="false" aria-controls="flush-collapseOne">
        {{ question }}
      </button>
    </h2>
    <div :id="'faqAccordionItem' + index " class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#faqAccordion">
      <div class="accordion-body">{{ answer }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent ({
    props: ["question", "answer", "index"],
    setup(props) {
        console.log(props.index)
    }
})
</script>

<style>
</style>