<template>
  <nav class="
      navbar
      w-100
      navbar-expand-sm
      bg-opacity-75 bg-primary
      navbar-light
      shadow-lg
      sticky-top
    ">
    <ul class="navbar-nav ms-auto">
      <li class="nav-item active pe-4">
        <router-link to="/" class="nav-link">
          {{ $t("buttons.login") }}
        </router-link>
      </li>
      <li class="nav-item pe-4">
        <router-link to="/requirements" class="nav-link">
          {{ $t("buttons.requirements") }}
        </router-link>
      </li>
      <li class="nav-item pe-4">
        <router-link to="/tutorial" class="nav-link">

          {{ $t("buttons.tutorial") }}
        </router-link>
      </li>
      <li class="nav-item pe-4">
        <router-link to="/faq" class="nav-link">
          {{
              $t("buttons.faq")
          }}
        </router-link>
      </li>
      <!-- <li class="nav-item pe-4">
                <a
                    class="nav-link"
                    href="https://portal.amaze360.nl/?page=highscores"
                >{{ $t("buttons.highScores") }}</a>
            </li>-->

      <li class="nav-item dropdown pe-4">
        <language-selector />
      </li>
    </ul>

  </nav>
</template>
