<template>
  <div class="container-fluid h-75">
    <div class="row h-25">
      <the-launch-navbar />
    </div>
    <div class="row h-25 align-items-center">
      <the-waiting-room>
        <router-link to="/tutorial">
          <base-secondary-button>{{ $t("buttons.previous") }}</base-secondary-button>
        </router-link>
        <base-primary-button>{{ $t("buttons.readyToPlay") }}</base-primary-button>
      </the-waiting-room>
    </div>
  </div>
</template>

<script>
import TheWaitingRoom from "../components/waitingroom/TheWaitingRoom.vue";
import TheLaunchNavBar from "../components/layout/TheLaunchNavBar.vue";
import { loginCheck } from "@/service/reuseable-functions";
export default {
  components: {
    "the-waiting-room": TheWaitingRoom,
    "the-launch-navbar": TheLaunchNavBar

  },
  setup() {
    loginCheck()
  }
};
</script>

<style>
</style>