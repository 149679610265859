<template>
  <faq-list/>
</template>

<script>
import FaqList from "@/components/faq/FaqList.vue" 
export default {
components:{
    'faq-list':FaqList
}
}
</script>

<style>

</style>