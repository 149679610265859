<template>
    <div :class="{ ready: playerStatus }">
        <img class="avatar" src="../../assets/avatars/robot-avatar-1.png" />
        <div>{{ nickName }}</div>
    </div>
</template>

<script>
export default {
    props: ["nickName", "playerStatus"],

}
</script>

<style>
.avatar {
    max-width: 55%;
}
.ready {
    animation: ready 2s forwards;
}
@keyframes ready {
    from {
    }
    to {
        transform: translate(300%, 70%) rotate(360deg);
        filter: grayscale(0%);
    }
}
</style>