<template>
  <div class="container-fluid h-75">
    <div class="row h-25">
      <the-launch-navbar v-if="isLoggedIn" />
    </div>
    <div class="row h-25 align-items-center">
      <game-tutorial>
        <div class="container">
          <div class="row">
            <router-link v-if="isLoggedIn" class="col-6" to="/requirements">
              <base-secondary-button>{{ $t("buttons.previous") }}</base-secondary-button>
            </router-link>
            <router-link v-if="isLoggedIn" class="col-6" to="/waitingroom">
              <base-primary-button>{{ $t("buttons.next") }}</base-primary-button>
            </router-link>
          </div>
        </div>
      </game-tutorial>
    </div>
  </div>
</template>

<script>
import GameTutorial from "../components/tutorial/GameTutorial.vue"
import TheLaunchNavBar from "../components/layout/TheLaunchNavBar.vue"
export default {
  data() {
    return {
      isLoggedIn: false
    }
  },
  components: {
    "game-tutorial": GameTutorial,
    "the-launch-navbar": TheLaunchNavBar

  },
  created() {
    if (sessionStorage.getItem('isLoggedIn')) {
      this.isLoggedIn = true
    }
  }
};
</script>

<style>
</style>